import axios from 'axios';


//https://homeboq.com

const api = axios.create({
    baseURL: 'https://homeboq.com/api/user',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

export const getPosts = async (page, search = '') => api.get(`/blog/get/${page}`);
export const getBookings = async (page) => api.get(`/bookings/get/${page}`);
export const getBookingById = async (id) => api.get(`booking/getone/${id}`)
export const getPost = async (slug) => api.get(`/blog/posts/${slug}`);
export const createBooking = async (data) => api.post('/booking/create', data);
export const verifyPaymentReq = async (tid, data) => api.post(`/payment/verify/${tid}`, data)
export const createContact = async (data) => api.post('/contact/create', data);
export const createCallback = async (data) => api.post('/callback/create', data);


export const registerOtp = async (data) => api.post('/auth/register/otp', data);
export const verifyRegister = async (data) => api.post('/auth/register/verify', data)
export const checkAuth = async () => api.get('/auth/register/check');
export const logout = async () => api.get('/auth/logout');

export const loginOtp = async (data) => api.post('/auth/login/otp', data);
export const loginVerify = async (data) => api.post('/auth/login/verify', data)
