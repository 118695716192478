import ImageGallery from "react-image-gallery";

const WaterProofingOld = () => {


 
      
    return(
        <div className="page-content">
             <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                           Water Proofing <span className="color-primary">(Old Building)</span>
                        </h1>
                    </div>
                </div>
            </section>


<section id="solution">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">Our <span className="color-primary">Solutions</span></h1>
                    </div>
                    <div className="row solution gx-5 gy-5">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/commercial-roof-waterproofing.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Roof Waterproofing</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/bathroom.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Bathroom Waterproofing</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/ef07efe0d1b909d224f8fc2439a24658.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Internal Walls Waterproofg</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/10455a1050fce9ef551823b92b86981870744.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">External Waterproofing.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/Water-Tanks-Internal-Waterproofing.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Water tanks Waterproofing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WaterProofingOld;