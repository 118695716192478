import ImageGallery from "react-image-gallery";

const Tiling = () => {


 
      
    return(
        <div className="page-content">
             <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                        Tiling and Epoxy Flooring Solutions
                        </h1>
                    </div>
                </div>
            </section>


<section id="solution">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">Our <span className="color-primary">Solutions</span></h1>
                    </div>
                    <div className="row solution gx-5 gy-5">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/Tile_adhesive_guide.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Tile adhesives</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/how-to-grout-a-tile-floor-5208084-hero-b781e1682f3e442ca8f199718bb9bc6d.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Tile Grouts</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/tiling-1.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Tile on tile solutions.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/light-brown-colored-laminate-floor-in-modern-kitchen.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Kitchen area floorings</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/services/e6473945abc8b72854d9d7561dd7ba0d.jpg" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Play area floorings</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Tiling;